button.chakra-button:disabled:hover {
  background-color: gray;
}


/* .pagination {
  display: flex;
  list-style-type: none;
  gap:20px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination > .page-item.active {
  font-weight: bold;
} */

/* Основной контейнер пагинации */
ul.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
}

/* Стили для элементов пагинации */
li.page-item {
  margin: 0 5px;
}

/* Стили для активной страницы */
li.page-item.active a.page-link {
  background-color: var(--chakra-colors-blue-400); /* Голубой цвет из Horizon UI */
  color: #fff;
  border: 1px solid var(--chakra-colors-blue-400);
}

/* Стили для ссылок на страницы */
a.page-link {
  text-decoration: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--chakra-colors-blue-400) /* Голубой цвет из Horizon UI */;
  transition: background-color 0.3s, color 0.3s;
}

a.page-link:hover {
  background-color: var(--chakra-colors-blue-400) /* Голубой цвет из Horizon UI */;
  color: #fff;
}

/* Стили для ссылок "Previous" и "Next" */
.next-button, .prev-button {
  font-weight: bold;
  text-transform: uppercase;
}

/* Скрытие многоточия для маленьких экранов (можно настроить на свое усмотрение) */
@media (max-width: 768px) {
  a[aria-label^="Jump"] {
      display: none;
  }
}
